.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2rem;
  grid-template-rows: var(--gridHeight);
}

.gridrow {
  display: grid;
  margin: 6rem;
}

.infoCardHeaderLeft {
  grid-column: 1 / 2;
  justify-content: flex-end;
  display: flex;
  align-self: center;
}

.infoCardDetailsLeft {
  grid-column: 2 / 5;
  justify-content: flex-start;
  display: flex;
  align-self: center;
}

.infoCardDetailsLeft .title {
  justify-content: flex-start;
  display: flex;
  align-self: center;
}

.infoCardDetailsLeft .description {
  justify-content: flex-start;
  display: flex;
  align-self: center;
}

.infoCardHeaderRight {
  grid-column: 5 / 6;
  justify-content: flex-start;
  display: flex;
  align-self: center;
}

.infoCardDetailsRight {
  grid-column: 2 / 5;
  justify-content: flex-end;
  display: flex;
  align-self: center;
}

.infoCardHeaderRight img {
  width: var(--gridHeight);
  height: var(--gridHeight);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}


.infoCardHeaderLeft img {
  width: var(--gridHeight);
  height: var(--gridHeight);
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}




.infoCardDetailsRight .title {
  justify-content: flex-end;
  display: flex;
  align-self: center;
}

.infoCardDetailsRight .description {
  justify-content: flex-end;
  display: flex;
  align-self: center;
}

.headerText {
  font-size: 100px;
  font-weight: bold;
}

.bodyText {
  font-size: 40px;
  font-weight: lighter;
}

.clickable {
  cursor: pointer;
}


@media (max-width: 1275px) {
  .headerText {
    font-size: 30px;
    font-weight: bold;
  }

  .bodyText {
    font-size: 15px;
    font-weight: lighter;
  }

  .infoCardDetailsRight {
    grid-column: 1 / 5;
  }

  .infoCardDetailsLeft {
    grid-column: 2 / 6;
  }

  .gridrow {
    display: grid;
    margin: .5rem;
  }

}