

.pictureCenter {
    display: flex;
    align-content: flex-start;
    justify-content: center;
    align-items: flex-start;
}

.goobContainer {
    position: relative;
    width: 100%; /* Set the width to match the image width */
    height: auto; /* Let the height adjust based on the image aspect ratio */
    overflow: hidden;
    max-width: 1200px;
    max-height: 1200px;
}
  
img {
    width: 100%;
    display: block;
    margin: auto;
}

.centered-div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%; /* Adjust the width of the centered div */
    height: 50%; /* Adjust the height of the centered div */
    /* background-color: rgba(0, 255, 0, 0.5); */
    text-align: center; /* Optionally center text/content within the div */
    padding: 20px; /* Adjust padding as needed */
    
}

.clickable-area-sam {
    position: absolute;
    top: 13%;
    left: 10%; /* Adjust the left position of the clickable area */
    width: 15%; /* Adjust the width of the clickable area */
    height: 20%; /* Adjust the height of the clickable area */
    /* background-color: rgba(255, 0, 0, 0.5);  */
    cursor: pointer;
}

.clickable-area-tony {
    position: absolute;
    top: 5%;
    left: 69.5%; /* Adjust the left position of the clickable area */
    width: 15%; /* Adjust the width of the clickable area */
    height: 20%; /* Adjust the height of the clickable area */
    /* background-color: rgba(255, 0, 0, 0.5);  */
    cursor: pointer;
}
  


h1 {
    text-align: center;
    padding-bottom: 20px;
    max-width: 500px;
    margin: 0 auto;
    font-size: 5em;
  }
