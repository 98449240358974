:root {
  --brown: #C16215;
  --blue: #083752;
  --blue2: #023C5D;
  /* --white: #E1DED5 ; */
  --white: #FFFFFF;
  --tan: #DCC294;
  --gridHeight: 300px
}

@import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
}


@media (max-width: 1275px) {
  :root {
    --gridHeight: 100px
  }

}

html {
  width: 100%;
}


.boopGrid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2rem;
  grid-template-rows: var(--gridHeight);

}

.boopGridrow {
  display: grid;
  justify-content: center;
  align-self: center;
  width: 100%;
  max-width: 600px;
  height: 100%;
  position: relative;
  top: -300px;
  left: 0;
}

.samNose {
  grid-column: 1 / 2;
  justify-content: center;
  display: flex;
  align-self: center;
  border: green solid 5px;
}

.tonyNose {
  grid-column: 5 / 6;
  justify-content: center;
  display: flex;
  align-self: center;
  border: red solid 5px;
}

.headerText {
  font-size: xx-large;
  font-family: Brush Script MT, Brush Script Std, cursive;
  color: var(--white);
}

.bodyText {
  font-size: large;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  color: var(--white);
}

body {
  /* font-family: Brush Script MT, Brush Script Std, cursive; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  background: radial-gradient(ellipse at bottom, var(--white) 0%, var(--blue) 90%, var(--blue) 100%);
  overflow-x: hidden;
  background-size: 100% 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  /* fallback */
  background-color: var(--brown);

}

.rotate {
  animation: rotation 1s ease infinite;
}


@keyframes rotation {

  0%,
  100% {
    transform: rotate(-3deg);
  }

  50% {
    transform: rotate(3deg);
  }
}