.date-heading {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    font-weight: bold;
    color: black;
}

.events-side.left .timeline-event,
.events-side.right .timeline-event {
    margin-bottom: 20px;
}

.events-side.left {
    padding-right: 50%;
    text-align: right;
}

.events-side.right {
    padding-left: 50%;
    text-align: left;
}

.event-content {
    display: inline-block;
    padding: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    background: #333;
    margin-top: 10px;
    /* Adjust as needed */
    color: #fff;
}

.event-time {
    font-weight: bold;
}


.timeline::after {
    content: '';
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    justify-content: center;
    background: #fff;
    /* The color of the line */
}

.timeline {
    position: relative;
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    /* Adds vertical space around the entire timeline */
}

.timeline-day {
    position: relative;
    width: 100%;
    margin-bottom: 60px;
    /* Space between each day */
}

.timeline-day .date-heading {
    position: relative;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    /* Space below the date heading */
    font-size: 24px;
    font-weight: bold;
    color: #000;
    /* Text color */
    z-index: 2;
    /* Ensure the heading appears above the line */
}

.timeline-day .date-heading.right {
    width: 50%;
    margin-left: 50%;
    text-align: left;
}

.timeline-day .date-heading.left {
    width: 50%;
    margin-left: 0%;
    text-align: right;
}

.event-info {
    display: flex;
    align-items: center;
    /* Aligns the text and image vertically */
}

.event-description {
    margin: 10px;
}

.event-image {
    width: auto;
    height: auto;
    max-width: 150px;
    /* Maximum width of the image */
    max-height: 150px;
    /* Maximum height of the image */
    border-radius: 10px;
    /* Optional: Adds rounded corners to the image */

}


@media (max-width: 1275px) {
    .event-image {
        width: auto;
        height: auto;
        max-width: 50px;
        /* Maximum width of the image */
        max-height: 50px;
        /* Maximum height of the image */
        border-radius: 10px;
        /* Optional: Adds rounded corners to the image */
    }
}