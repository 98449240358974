.loading-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.visible {
  display: flex;
}

.hidden {
  display: none;
}

.loading-bell {
  font-size: 40px;
  /* Adjust the size as needed */
  animation: rotateBell .5s linear infinite;
}

@keyframes rotateBell {

  0%,
  100% {
    transform: rotate(-15deg);
  }

  50% {
    transform: rotate(30deg);
  }
}