.navbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 10;
  background: radial-gradient(ellipse at top, var(--blue) 0%, transparent 100%);

}

nav ul {
  display: flex;
  min-width: 200px;
  overflow: hidden;
}

nav ul li {
  list-style: none;
  overflow: hidden;
}

nav ul li a {
  display: block;
  text-decoration: none;
  color: var(--white);
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.5rem;
  position: relative;
  transition: color 0.3s ease;
  /* Add a transition for color change */
}

nav ul li a.active {
  background-color: var(--blue);
}

nav ul li a:not(.active):hover {
  transform-origin: center;
  transition: transform 0.3s ease;
  border-radius: 0.5rem;
}

nav ul li a:not(.active)::before {
  content: "";
  position: absolute;
  top: 50%;
  /* Center the pseudo-element vertically */
  left: 50%;
  /* Center the pseudo-element horizontally */
  transform: translate(-50%, -50%);
  /* Center it properly */
  width: 0;
  height: 0;
  background: var(--tan);
  transform-origin: center;
  /* Set the transform origin to the center */
  transition: width 0.3s ease, height 0.3s ease;
  /* Add transitions for width and height changes */
  z-index: -100;
  border-radius: 0.5rem;
}

nav ul li a:not(.active):hover::before {
  width: 100%;
  height: 100%;
}

nav .menu {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  height: 2.5rem;
  width: 2.75rem;
  display: none;
  padding: .3rem;
}

nav .menu span {
  height: 0.35rem;
  width: 100%;
  background-color: var(--white);
  border-radius: 0.2rem;
}

@media (max-width: 1676px) {
  nav .menu {
    display: flex;
    z-index: 10;
  }

  nav .menu:not(.active)::before {
    content: "";
    position: absolute;
    top: 50%;
    /* Center the pseudo-element vertically */
    left: 50%;
    /* Center the pseudo-element horizontally */
    transform: translate(-50%, -50%);
    /* Center it properly */
    width: 0;
    height: 0;
    background: var(--tan);
    transform-origin: center;
    /* Set the transform origin to the center */
    transition: width 0.3s ease, height 0.3s ease;
    /* Add transitions for width and height changes */
    z-index: -1;
    border-radius: 0.5rem;
  }

  nav .menu:not(.active):hover::before {
    width: 100%;
    height: 100%;
  }


  nav {
    flex-direction: column;
    align-items: start;
  }

  nav ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.25rem;
    align-items: center;
    padding-left: 0px;
  }

  nav ul.open {
    display: flex;
    padding-left: 0px;
  }

  nav ul li {
    width: 100%;
    text-align: center;
    margin-top: -200px;
    transition: .5s;
    transition-property: margin-top;
  }

  nav ul li.open {
    transition: .5s;
    transition-property: margin-top;
    margin-top: 0px;
  }

  nav ul li a {
    margin: 0.1rem 0.1rem;
  }

}