.searchContainer {
  text-align: center;
  margin: 20px;
  padding: 5px 5px 5px 5px;
  color: var(--white);
}


input {
  padding: 20px;
  font-size: 24px;
  width: 100%;
  max-width: 500px;
  /* You can adjust the maximum width as needed */
  border-radius: 5px;
  border: 0;
}