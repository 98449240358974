/* @import url('https://fonts.googleapis.com/css?family=Poppins:400,500,600,700&display=swap');
*{
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
} */

.gridRegisterInput {
  display: flex;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 2rem;
  grid-template-rows: var(--gridHeight);
}

.gridrowRegisterInput {
  display: flex;
  margin: 6rem;
}

.container {
  max-width: 800px;
  background: var(--white);
  width: 800px;
  padding: 25px 40px 10px 40px;
  border-radius: 5%;
  height: auto;
}

.containerContainer {
  display: flex;
  align-self: center;
  align-content: flex-start;
  justify-content: center;
  align-items: center;
  padding: 25px 25px 25px 25px;
  min-width: 400px;
}

.container .text {
  text-align: center;
  font-size: 41px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  background: var(--blue);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.container form {
  padding: 10px 0 0 0;
}

.container form .form-row {
  display: flex;
  margin: 32px 0;
}

.container form .form-row-label {
  display: flex;
  margin: 0;
  padding-left: 50px;
  padding-right: 50px;

}

form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}

form .form-row .textarea {
  height: 70px;
}

.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
}

.checkbox {
  display: flex;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20%;

}

.checkbox input {
  order: 1;
  width: 20px;
  /* Adjust the width as needed */
  height: 20px;
  /* Adjust the height as needed */
}

.checkbox label {
  order: 2;
  margin-left: 40px;
}




.input-data input:focus~label,
.textarea textarea:focus~label,
.input-data input:valid~label,
.isValid textarea~label {
  transform: translateY(-20px);
  font-size: 14px;
  color: var(--blue);
}

.textarea textarea {
  resize: none;
  padding-top: 10px;
}

.textInput {
  background-color: var(--white);
}

.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.textarea label {
  width: 100%;
  bottom: 40px;
  background: var(--white);
}

.input-data .underline {
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: var(--blue);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}

.input-data input:focus~.underline:before,
.input-data input:valid~.underline:before,
.textarea textarea:focus~.underline:before,
.isValid textarea~.underline::before {
  transform: scale(1);
}

.submit-btn .input-data {
  overflow: hidden;
  border-radius: .5rem;
}

.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: var(--blue);
  transition: all 0.4s;
}

.submit-btn .input-data:hover .inner {
  background: var(--tan);
  border-radius: .5rem;
}

.submit-btn .input-data input {
  background: none;
  border-bottom: var(--blue) 5px;
  color: var(--white);
  font-size: 17px;
  font-weight: bolder;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
  border-radius: .5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.dropdown {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.dropdown-select {
  width: 100%;
  padding: 10px;
  font-size: 17px;
  border: 2px solid var(--tan);
  border-radius: 5px;
  background-color: var(--white);
  cursor: pointer;
}

.dropdown-options {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 2px solid var(--tan);
  border-top: none;
  border-radius: 5px 5px 5px 5px;
  background-color: var(--white);
  z-index: 6;
}

.dropdown-options.open {
  display: block;
}

.dropdown-options a {
  display: block;
  padding: 10px;
  font-size: 16px;
  color: var(--blue);
  text-decoration: none;
  transition: background-color 0.3s;
}

.dropdown-options a:hover {
  background-color: var(--tan);
}

/* Show dropdown options on hover */

/* .dropdown:hover .dropdown-options {
  display: block;
} */

.dietLabel {
  pointer-events: none;
  bottom: 0;
  font-size: 16px;
  transition: all 0.3s ease;
  padding: 0;
  margin: 0;
}

.guestGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  grid-template-rows: var(--gridHeight);

}


@media (max-width: 1275px) {
  .container {
    max-width: 400px;
    width: 320px;
    padding: 0px 0px 0px 0px;

  }

  .guestGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 2rem;
    grid-template-rows: 250px;

  }

  .container form .form-row {
    display: flex;
    margin: 32px 12px;
  }


  .containerContainer {
    padding: 0px 0px 0px 0px;
    min-width: 320px;
  }

}