.registry-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }

  h2 {
    text-align: center;
    padding-bottom: 20px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  .registry-item img {
    max-width: 200px;
    max-height: 200px;
    border-radius: 50%;
  }
  
  .registry-item p {
    text-align: center;
    order: 2;
  }
  
  .registry-item button {
    order: 3;
    margin: 0 auto;
    padding: 10px 20px;
    background-color: #0070ba;
    width: 250px;  /* Set the width */
    height: 50px;  /* Set the height */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.0em;
  }
  
  .registry-item button:hover {
    background-color: #005a94;
  }