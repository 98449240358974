.initialCircle {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: var(--tan);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--black);
    font-size: 45px;
}

.guestCardContainer {
    position: relative;
    background: var(--white);
    padding: 5px 5px 5px 5px;
    border-radius: 5%;
    height: 200px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column
}

.guestCardContainerContainer {
    display: flex;
    align-self: center;
    align-content: flex-start;
    justify-content: center;
    align-items: center;
    padding: 25px 25px 25px 25px;
    margin: auto;
    min-width: 25px;
}

.guestName {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    font-family: 'Poppins', sans-serif;
    background: black;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    padding: 5px;
    margin: 2px;
    width: fit-content;
}

.styled-button {
    background: var(--blue);
    color: var(--white);
    font-size: 17px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    font-family: 'Poppins', sans-serif;
}

.styled-button:hover:not(:disabled) {
    background: var(--tan);
    transform: translateY(-2px);
}

.styled-button:active:not(:disabled) {
    transform: translateY(1px);
}

.centered-button {
    margin: 10px auto;
    display: block;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    min-width: 300px;
}

.modal-content {
    background: var(--white);
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    width: 50%;
    /* Adjust the width as needed */
    max-width: 600px;
    /* Optional: to prevent the modal from becoming too wide on large screens */
    min-width: 300px;
    position: relative;
}

.styled-button+.styled-button {
    margin-left: 20px;
    /* Adds space between the buttons */
}

.styled-button:disabled {
    cursor: not-allowed;
}


.dietary-preferences {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
    font-family: 'Poppins', sans-serif;
    /* Matching the font with guest cards */
}

.dietary-preferences:disabled {
    cursor: not-allowed;
}

.styled-select {
    background: var(--blue);
    color: var(--white);
    font-size: 17px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
    font-family: 'Poppins', sans-serif;
    -webkit-appearance: none;
    /* Removes default styling for select dropdown */
    -moz-appearance: none;
    /* Removes default styling for select dropdown */
    appearance: none;
}

.styled-select:not(:disabled) {
    cursor: pointer;
}

.styled-select:disabled {
    cursor: not-allowed;
}



/* Optional: to add a custom dropdown arrow */
.styled-select-wrapper {
    position: relative;
    display: inline-block;
}

.styled-select-wrapper:after {
    content: '▼';
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: var(--white);
}

.rsvp-flag {
    position: absolute;
    top: 10px;
    /* Adjust as needed */
    right: 10px;
    /* Adjust as needed */
    color: var(--blue);
    padding: 5px;
    border-radius: 0 0 0 5px;
    /* Rounded bottom-left corner */
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-align: center;
    z-index: 0;
}

.selected {
    background-color: var(--tan);
    /* Change to your highlight color */
    color: var(--white);
}

.loading-screen {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
}

.bottom-controls {
    display: flex;
    justify-content: space-between;
    /* Or adjust as per your design */
    align-items: flex-end;
    margin-top: 20px;
    /* Space from the content above */
}

.modal-content .exit-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
    font-size: 40px; /* Adjust size as needed */
    padding: 0px 15px; /* Gives some padding around the X */
}