.guesterGrid {
    display: flex;
    justify-content: space-between;
  }
  
  .guesterColumn {
    flex: 1;
    margin: 10px;
  }
  
  .guesterColumn h3 {
    text-align: center;
  }
  
  .guesterColumn ul {
    list-style-type: none;
    padding: 0;
  }
  
  .guesterColumn li {
    margin: 5px 0;
  }