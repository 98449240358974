body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    justify-content: center;
    align-items: center;
    min-height: 100%;
    background: radial-gradient(ellipse at bottom, var(--white) 0%, var(--blue) 90%, var(--blue) 100%);
    overflow-x: hidden;
    background-size: 100% 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-color: var(--brown); /* fallback */
  }
  
  .headerText {
    font-size: xx-large;
    font-family: 'Brush Script MT', 'Brush Script Std', cursive;
    color: var(--white);
  }
  
  .bodyText {
    font-size: large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: var(--white);
  }
  
  .faq {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .faq-item {
    margin-bottom: 10px;
  }
  
  .faq-question {
    cursor: pointer;
    padding: 10px;
    background-color: var(--blue);
    border: 0px solid #ddd;
    font-size: large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: var(--white);
  }
  
  .faq-answer {
    display: none;
    padding: 10px;
    border: 0px solid #ddd;
    border-top: none;
    background-color: transparent;
    font-size: large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    color: black;
  }
  
  .faq-answer.active {
    display: block;
  }

  .faq-answer a {
    color: var(--white);
  }
  